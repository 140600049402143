<script>
import axios from 'axios'
import {toast} from "vue3-toastify";
import { QuillEditor } from '@vueup/vue-quill'
export default {
  name: "SortSentences",
  props: ['lesson'],
  components: { QuillEditor},
  data() {
    return {
      content:'',
      d: this.lesson.exercise.data_object,
    }
  },
  mounted() {
    this.prepare_content()


  },
  watch: {
    lesson() {
      this.d = this.lesson.exercise.data_object
      this.prepare_content()
    },
  },
  methods: {
    prepare_content(){
      this.content = this.d.sentences.join("\n")
    },
    prepare_data_for_save() {
      this.d.sentences = this.content.split("\n")
    },

    saveChanges() {
      this.prepare_data_for_save()
      axios.put(`/oj/exercises/${this.lesson.id}`, {
        exercise: this.lesson.exercise,
        data_object: this.d
      }).then(response => {
        toast.success('Uloženo')
      }).catch(e => {
        console.log(e)
        toast.error(e.response.data.errors)
      })
    }

  }
}
/* "data_object": {
      "sentences": [
        "What time are you open?",
        "We're open from 10 a.m. to 8 p.m., six days a week.",
        "Can you tell me where the frozen food section is?",
        "Hom much does this silk blouse cost?",
        "Have you got anything cheaper, please?",
        "Do you have those linen trousers in size L?",
        "Sorry, we don't have any left.",
        "That's a pity. Do you know anywhere else I could try?"
      ]
    },
    "exercise_type_class": "SortSentences"
    }
     */
</script>

<template>
  <div class="well">
    <div class="row-fluid">
      <div class="span6">
        <div class="control-group"><label for="aboveText" class="control-label optional">Text nad cvičením</label>
          <div class="controls">
            <textarea v-model="lesson.exercise.aboveText"  name="aboveText" id="aboveText" rows="8" class="span12" cols="80"></textarea>
            <p class="help-block" style="color: #999;">Text, který se zobrazí nad cvičením. Měl by obsahovat zadání cvičení, kde studenta informujeme o tom, co od něho ve cvičení požadujeme a jak má postupovat.</p></div></div>
      </div>
      <div class="span6">
        <div class="control-group"><label for="secondaryAboveText" class="control-label optional">Sekundární text nad cvičením</label>
          <div class="controls">
            <textarea v-model="lesson.exercise.secondaryAboveText" name="secondaryAboveText" id="secondaryAboveText" rows="8" class="span12" cols="80"></textarea>
            <p class="help-block" style="color: #999;">Sekundární text nad cvičením - slouží zejména pro text ve vyučovaném jazyce</p></div></div>
      </div>
    </div>
  </div>
  <div class="control-group"><label for="exerciseText" class="control-label optional">Text cvičení</label>
    <div class="controls">
      <quill-editor v-model:content="lesson.exercise.exerciseText"
                    contentType="html"
      ></quill-editor>
      <p class="help-block" style="color: #999;">Text cvičení, například text konverzace se kterou dále student pracuje ve cvičení, apod.</p>
    </div>
  </div>





  <div class="well">
    <div class="control-group"><label for="content" class="control-label required">Obsah cvičení</label>
      <div class="controls">
        <textarea name="content" id="content" rows="15" class="span12" cols="80" v-model="content"></textarea></div></div>
        <div class="alert alert-info">
          <h4 class="alert-heading">Věty</h4>
          Do pole s obsahem cvičení vkládejte věty ve správném pořadí. Každá jedna věta musí být vložena na nový řádek.
        </div>
  </div>

  <div class="form-actions">
    <input type="submit" name="submit" id="submit" value="Uložit" class="btn btn btn-primary btn-large" @click.prevent="saveChanges">
  </div>

</template>

<style scoped>

</style>