<script>

import {toast} from "vue3-toastify";
import Popper from "vue3-popper";
import axios from 'axios'
export default {
  name: "FillUp",
  components: {Popper},
  props: ['lesson'],
  data() {
    return {
      d: this.lesson.exercise.data_object,
      content:'',
      vgrid: [],
      n_rows: parseInt(this.lesson.exercise.data_object.rows),
      n_cols: parseInt(this.lesson.exercise.data_object.cols),
      out: {}
    }
  },
  watch: {
    lesson() {
      this.d = this.lesson.exercise.data_object
      this.makeContent()

    },
  },
  mounted() {
    this.makeContent()
  },
  methods: {

    makeContent() {

    },

    addItemToContent(token){
      token.content.push('')
    },


/*
// fix vložení do textu, odstranení přebytečného formátování
$(".fillupContent").on("paste", function(event){

  var fillup = $(this);

  setTimeout(function() {
    fillup.html((fillup).html().replace(/<(?!\s*\/?(br|b|strong)\b)[^>]+>/ig,''));

  }, 1);

});

"d": {
      "type": "fillup",
      "fillups": {
        "1": {
          "id": 1,
          "tokens": [
            {
              "id": 1,
              "type": "text",
              "content": "1. You  "
            },
            {
              "id": 2,
              "type": "fillup",
              "content": [
                "have"
              ]
            },
            {
              "id": 3,
              "type": "text",
              "content": "    "
            },
            {
              "id": 4,
              "type": "fillup",
              "content": [
                "got"
              ]
            },
            {
              "id": 5,
              "type": "text",
              "content": "             a very nice job!    <br>2. She  "
            },
            {
              "id": 6,
              "type": "fillup",
              "content": [
                "has"
              ]
            },
            {
              "id": 7,
              "type": "text",
              "content": "    "
            },
            {
              "id": 8,
              "type": "fillup",
              "content": [
                "got"
              ]
            },
            {
              "id": 9,
              "type": "text",
              "content": "             a good qualification.  <br>  3. I  "
            },
    */

    removeBlok(blok){
      this.d.fillups = this.d.fillups.filter((item) => item !== blok)
    },
    removeContentItem(content,k){
      content.splice(k,1)
    },

    onCEChange(t, event){
      const element = event.target;
      const htmlContent = element.innerHTML
      console.log('onCEChange', t, htmlContent)
      if (t.content_tmp != htmlContent){
        t.content_tmp = htmlContent;
      }
    },
    addBlok(){

      // max id of fillup

      let id = 1
      for(let i in this.d.fillups){
        if(parseInt(this.d.fillups[i].id) > id){
          id = this.d.fillups[i].id
        }
      }
      id++

      this.d.fillups[`${id}`] = {
          size: 1,
          tokens:[{
            type:'text',
            content:' '
          }],
          id: id,
          wrong: [""]
      }
    },
    saveChanges() {

      for (let b in this.d.fillups) {
        let blok = this.d.fillups[b]
        for (let t in blok.tokens) {
          let token = blok.tokens[t]
          if (token.content_tmp) {
            token.content = token.content_tmp
            delete token.content_tmp
          }
        }
      }

      axios.put(`/oj/exercises/${this.lesson.id}`, {
        exercise: this.lesson.exercise,
        data_object: this.d
      }).then(response =>{
        toast.success('Uloženo')
      }).catch(e => {
        console.log(e)
         toast.error(e.response.data.errors)
      })


      this.$emit('save', this.d)
    },
    appendFillup(blok){
      blok.tokens.push({type:'fillup', content:['']})
      blok.tokens.push({type:'text', content:' '})
    }

  }
}
</script>

<template>

  <div class="control-group"><label for="type" class="control-label required">Typ cvičení</label>
    <div class="controls">
      <select name="type" id="type">
        <option value="fillup" label="Vyplnění bez možností" selected="selected">Vyplnění bez možností</option>
        <option value="dragdrop" label="Vyplnění pomocí drag &amp; drop">Vyplnění pomocí drag &amp; drop</option>
      </select>
      <p class="help-block" style="color: #999;">Touto volbou můžete ovlivnit, jakým způsobem bude student vyplňovat vybrané mezery v textu.</p>
    </div>
  </div>


  <p>
    <a @click.prevent="addBlok"  id="addFillup" href="#" class="btn btn-success"><i class="icon-plus-sign icon-white"></i> Přidat blok</a>
  </p>


  <div id="exerciseFillups" class="clearfix">

    <div :id="`fillupItem_${i}`" class="fillupItem" v-for="(blok, i) in d.fillups">
      <div class="control-group">
        <label class="control-label required" for="fillupSize">Velikost polí pro vyplnění možností</label>
        <div class="controls">
          <select id="fillupSize"  v-model="blok.size">
            <option label="Úzké pole" value="4">Pole pro jedno písmeno až slabiku</option>
            <option label="Úzké pole" value="1">Úzké pole</option>
            <option label="Normální pole" value="2" selected="selected">Normální pole</option>
            <option label="Široké pole" value="3">Široké pole</option>
          </select>
          <p style="color: #999;" class="help-block">Touto volbou můžete ovlivnit, jak široká budou formulářová pole pro vyplnění možností.</p>
        </div>
      </div>



      <div class="btn-toolbar fillupToolbar">
        <div class="btn-group">
          <a href="#" data-fillupid="1" class="btn btn-danger" @click.prevent="removeBlok(blok)"><i class="icon-trash icon-white"></i> Odebrat tento blok</a>
        </div>
      </div>


        <div class="row">
          <div class="col-md-8 lines-box fillup-editor">


            <template v-for="token in blok.tokens">
              <span v-if="token.type=='text'"
                    v-html="token.content" contenteditable="true"
                    @keydown="onCEChange(token, $event )"
                    class="text"
                    @keyup="onCEChange(token, $event )"></span>
              <Popper arrow  class="light" v-if="token.type=='fillup'">
                <span class='fillup' >.....</span>

                  <template #content>
                    <div v-for="(a,k) in token.content">
                        <input type="text" v-model="token.content[k]" /><a class="color-delete" href="#" @click.prevent="removeContentItem(token.content,k)"><span class="material-symbols-outlined">delete</span></a>
                    </div>
                    <button @click.prevent="addItemToContent(token)">+</button>
                  </template>
              </Popper>


      <!--        <Popper arrow  class="light">
                <span  class='fantomas' ></span>
                <template #content>

                    <input type="text" v-model="new_token" />
                    <button @click.prevent="addNewToken(token)">+</button>
                  </template>
              </Popper>-->
            </template>
            <button @click.prevent="appendFillup(blok)">+</button>
          </div>
          <div class="col-md-4 pl-3">
            <label for="fillupWrong_1">Špatné možnosti:</label>
            <textarea v-model="blok.wrong"
                      class="fillupWrong form-control"
                      rows="10"
            ></textarea>
          </div>
        </div>
    </div>


  </div>

  <div class="form-actions">
    <input type="submit" name="submit" id="submit" value="Uložit" class="btn btn btn-primary btn-large" @click.prevent="saveChanges">
  </div>

  <pre>
    in: {{d}}
  </pre>

</template>

<style >

.fillup-editor{
  line-height: 1.8em;
}

.fillupItem {

    position: relative;
    background: #F5F5F5;
    padding: 5px;

  select {
  width: 220px;

  background-color: #fff;

  border: 1px solid #ccc;

}

}
.fillupItem .fillupContent {
  background: #FFFFFF;
  border: 1px solid #CCCCCC;
  border-radius: 3px 3px 3px 3px;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075) inset;
  padding: 8px;
  margin-bottom: 10px;
  min-height: 140px;
  font-size: 15px;
  line-height: 22px;
}
.fillupItem .fillupContent span, .fillupItem .fillupContent b, .fillupItem .fillupContent strong {
  color: #FFFFFF;
  font-size: 14px;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
  vertical-align: baseline;
  white-space: nowrap;
  border-radius: 3px;
  padding: 3px 4px 3px 4px;
  background-color: #80C005;
  text-shadow: 1px 1px 1px #666666;
}

.fillupContent b{
  background: #5aacff;
}
.fillupToolbar {

    position: absolute;
    top: 5px;
    right: 5px;
    margin: 0;

}

</style>