<script>
import axios   from "axios";
import LessonFiles from "@/components/oj/courses/lesson-files.vue";
import ContentAttachments from "@/components/oj/courses/content-attachments.vue";
import {toast} from "vue3-toastify";
import { QuillEditor } from '@vueup/vue-quill'
import Editor from '@tinymce/tinymce-vue'
export default {
  name: "content-options",
  props:['lesson'],
  components: {LessonFiles, ContentAttachments, QuillEditor, Editor},
  data() {
    return {
      co: {},
      t1:`<ul><li>tato vazba nám slouží k označení stavu nebo opakované aktivity pouze v minulosti</li></ul>`,
    }
  },
  mounted() {
    this.t1 = this.lesson.content.text
  },
  watch: {
    lesson() {
      this.t1 = this.lesson.content.text
    },
  },
  methods: {
    saveChanges() {
      console.log('save changes', this.lesson)


      if (this.lesson.id === undefined) {
        axios.post('/oj/lessons.json', {lesson: this.lesson}).then(r=>{
          console.log('response', r)
           toast.success('Uloženo')
        }).catch(e => {
          console.log(e)
          toast.error(e.response.data.errors)
        })
      } else {

        axios.put('/oj/lessons/' + this.lesson.id + '.json', {lesson: this.lesson}).then(r => {
          console.log('response', r)
          toast.success('Uloženo')
        }).catch(e => {
          console.log(e)
          toast.error(e.response.data.errors)
        })
      }
    },

    sanitator(){
      console.log('sanitator')

      this.t1= this.lesson.content.text.replace(/>\s+</g, '><');
    }


  }
}

/*
Zq=ry*']2hmhZp>

lesson example: {
  "id": 205931,
  "name": "to je uvod",
  "lesson_discriminator": "content",
  "is_active": true,
  "inserted": "2024-10-15T00:22:02.000+02:00",
  "modified": "2024-11-24T17:10:17.000+01:00",
  "avatar_text": "",
  "avatar_text_mode": "AVATAR_TEXT_MODE_ONDEMAND_WITHOUT_TEXT",
  "transcript": "",
  "author_id": 516992,
  "secondary_name": "",
  "start_date": "1900-01-01T01:00:00.000+01:00",
  "order_in_week": 0,
  "milestone": 0,
  "milestone_text": "",
  "ac_tag": "",
  "position": 0,
  "block_id": 45446,
  "evaluate": 1,
  "trial_only": 0,
  "exercise": null,
  "content": {
    "id": 205931,
    "text": "",
    "video": "",
    "pdf": null,
    "type_id": 4,
    "perex": "",
    "template": "TEMPLATE_1_COLUMN"
  },
  "lesson_files": [
    {
      "id": 23197,
      "lesson_id": 205931,
      "original_name": "05_Otherwise (Un-Cut Remix).mp3",
      "real_name": "9e0f43b4289707f2d3486806fa9c1875.mp3",
      "label": "popisek",
      "type": "mp3",
      "text": "text k em pe 3",
      "autoplay": 0
    }
  ]
} */


</script>

<template>
  <div class="tab-content">
    <div id="info-tab" class="active tab-pane">

      <div class="form-horizontal">

        <div class="control-group"><label for="name" class="control-label required">Název obsahu</label>
          <div class="controls">
            <input type="text" name="name" id="name" v-model="lesson.name" class="input-xxlarge">
            <p class="help-block" style="color: #999;">Stručný název.</p>
          </div>
        </div>

        <div class="control-group"><label for="secondary_name" class="control-label optional">Sekundární název          obsahu</label>
          <div class="controls">
            <input type="text" name="secondary_name" id="secondary_name"  v-model="lesson.secondary_name" class="input-xxlarge">
            <p class="help-block" style="color: #999;">Sekundární název - slouží zejména pro název ve vyučovaném
              jazyce</p>
          </div>
        </div>

        <div class="control-group"><label for="perex" class="control-label optional">Úvod</label>
          <div class="controls">
            <textarea name="perex" id="perex" cols="60" rows="8" class="input-xxlarge" v-model="lesson.content.perex"></textarea>
            <p class="help-block" style="color: #999;">Stručný perex k obsahu. Je zobrazen vždy na úplném začátku       stránky.</p>
          </div>
        </div>


        <div class="control-group">
          <label for="text" class="control-label optional">Text</label>




          <Editor
              api-key="s033ao5dcl8b8qw7j6bhqodmsremp6va27oijf0cv3svx5lf"
              v-model="lesson.content.text"
              :init="{
    // plugins : 'image,code,table,fullscreen,link',
//        menubar:false,

  plugins: [
    'a11ychecker', 'advcode', 'advlist', 'anchor', 'autolink', 'codesample', 'fullscreen', 'help',
    'image', 'editimage', 'tinydrive', 'lists', 'link', 'media', 'powerpaste', 'preview',
    'searchreplace', 'table', 'tinymcespellchecker', 'visualblocks', 'wordcount'
  ],

               content_css : '/wswg.css',
                body_class: 'wswg',
                 toolbar: 'h1 h2 green alternate | undo redo | bold italic | forecolor backcolor | codesample | alignleft aligncenter alignright alignjustify | bullist numlist | link image',

//               toolbar: 'h1,h2,bold,underline,italic,|,blue,red,orange,green,alternate,|,clear,|,list,infobox,|,oj_table_vertical,right-panel,|,link,image,fullscreen,code'


                // moje nastavení
        entity_encoding : 'raw', // vrátí text bez překodování entit
          relative_urls : false, // definice, aby url byli relativní oproti celého webu



          convert_urls : false,
          paste_auto_cleanup_on_paste : true,

          resize: 'both',
          image_dimensions: false,
          object_resizing : false,
          height: '450px',
          //file_browser_callback: RoxyFileBrowser,

          oj_image_style : true,
          image_class_list: [
          {title: '100%', value: 'img100'},
          {title: '75%', value: 'img75'},
          {title: '50%', value: 'img50'},
          {title: '33%', value: 'img33'},
          {title: '25%', value: 'img25'}
          ]


            }"
          />


          <div v-html="lesson.content.text"></div><button @click="sanitator">SS</button>
        </div>


        <div class="control-group"><label for="transcript" class="control-label optional">Přepis nahrávky</label>
          <div class="controls">
            <textarea name="transcript" id="transcript" cols="40" rows="8" class="input-xxlarge"
            v-model="lesson.transcript"></textarea>
            <p class="help-block" style="color: #999;">Text, který si může student přečíst, když například nerozumí
              nahrávce</p></div>
        </div>
        <div class="control-group">
          <label for="type" class="control-label optional">Typ obsahu</label>
          <div class="controls">
            <select name="type" id="type" data-original-title="Typ obsahu" v-model="lesson.content.type_id"
                    data-content="Typ obsahu určuje jaký obsah je na stránce na prvním místě." data-trigger="hover"
                    rel="popover">
              <option value="1" label="Text">Text</option>
              <option value="2" label="Video">Video</option>
              <option value="3" label="PDF">PDF</option>
              <option value="4" label="Úvod" selected="selected">Úvod</option>
              <option value="5" label="Obrázky">Obrázky</option>
              <option value="6" label="Úspěch">Úspěch</option>
              <option value="8" label="Nahraj se">Nahraj se</option>
              <option value="9" label="Vyslovuj">Vyslovuj</option>
              <option value="10" label="Vyslovuj podle obrázků">Vyslovuj podle obrázků</option>
            </select>

          </div>

        </div>
        <div class="control-group">
          <label for="template" class="control-label optional">Šablona zobrazení</label>
          <div class="controls">
            <select name="template"
                    id="template"
                    data-original-title="Šablona zobrazení"
                    data-content="Šablona určuje rozložení obsahu na stránce."
                    data-trigger="hover"
                    rel="popover"
            v-model="lesson.content.template">
              <option value="TEMPLATE_1_COLUMN" label="TEMPLATE_1_COLUMN" selected="selected">TEMPLATE_1_COLUMN</option>
              <option value="TEMPLATE_2_COLUMN" label="TEMPLATE_2_COLUMN">TEMPLATE_2_COLUMN</option>
              <option value="TEMPLATE_2_COLUMN_DETAIL" label="TEMPLATE_2_COLUMN_DETAIL">TEMPLATE_2_COLUMN_DETAIL
              </option>
            </select>
          </div>
        </div>

        <!--
        <div class="control-group">
          <label for="pdf" class="control-label optional">PDF soubor</label>
          <div class="controls">
            <input type="hidden" name="MAX_FILE_SIZE" value="47185920" id="MAX_FILE_SIZE">
            <input type="file" name="pdf" id="pdf" data-original-title="PDF soubor"
                   data-content="PDF soubor s obsahem, ve veřejné části bude zobrazen ve speciální čtečce."
                   data-trigger="hover" rel="popover">
          </div>
        </div>-->

        <div class="control-group">
          <label for="is_active" class="control-label optional">Je obsah určen pro          použití?</label>
          <div class="controls">

              <input type="checkbox" name="is_active" id="is_active" value="1" checked="checked" v-model="lesson.is_acive">
              <p class="help-block" style="color: #999;">Určuje, zda bude obsah zobrazen ve výpisu kurzu.</p>
          </div>
        </div>

        <div class="control-group">
          <label for="trialOnly" class="control-label optional">Je obsah určen pouze pro TRIAL kurzu?</label>
          <div class="controls">
            <input type="checkbox" name="trialOnly" id="trialOnly"  value="1" v-model="lesson.trial_only">
            <p class="help-block" style="color: #999;">Obsah bude zobrazen pouze v trialu kurzu.</p>
          </div>
        </div>

        <div class="control-group"><label for="isNew" class="control-label optional">Výklad je nový</label>
          <div class="controls">
            <input type="checkbox" name="isNew" id="isNew" value="1" v-model="lesson.is_new">
            <p class="help-block" style="color: #999;">Pokud je výklad označen jako nový, tak bude označen štítkem i ve
              studovně.</p>
          </div>
        </div>

        <div class="control-group"><label for="acTag" class="control-label optional">ActiveCampaing TAG</label>
          <div class="controls">
            <input type="text" name="acTag" id="acTag" value="" class="input-xxlarge">
            <p class="help-block" style="color: #999;">Vyplňte název tagu, který bude do Active Campaign odeslán při
              navštívení této obsahové stránky</p>
          </div>
        </div>
<!--
        <div class="control-group">
          <label for="startDate" class="control-label optional">Zobrazit od (YYYY-MM-DD    HH:MM:SS)</label>
          <div class="controls">
            <input type="text" name="startDate" id="startDate" value="1900-01-01 00:00:00">
          </div>
        </div>
-->
        <div class="control-group"><label for="orderInWeek" class="control-label optional">Pořadí v týdnu</label>
          <div class="controls">
            <input type="text" name="orderInWeek" id="orderInWeek" v-model="lesson.order_in_week" class="input-xxlarge">
            <p class="help-block" style="color: #999;">Pořadí cvičení v týdnu (1 - pondělí, 2 - úterý, ...). Používá se
              pro postupně odemykající se kurz</p></div>
        </div>
        <div class="control-group"><label for="milestone" class="control-label optional">Milník</label>
          <div class="controls">
            <input type="text" name="milestone" id="milestone" v-model="lesson.milestone" class="input-xxlarge">
            <p class="help-block" style="color: #999;">Pořadové číslo milníku v rámci kurzu.</p>
          </div>
        </div>
        <div class="control-group"><label for="milestoneText" class="control-label optional">Popis milníku</label>
          <div class="controls">
            <textarea name="milestoneText" v-model="milestone_text" id="milestoneText" cols="40" rows="4" class="input-xxlarge"></textarea>
            <p class="help-block" style="color: #999;">Orientační popis milníku.</p>
          </div>
        </div>
<!--
        <div class="control-group">
          <label class="control-label optional">Přiložené obrázky</label>
          <div class="controls">
            <fieldset id="fieldset-image">


              <input type="submit" name="image[0][add]" id="image-0-add" value="Přidat další" last-group="image-0"
                     class="contentedit-add"></fieldset>
          </div>
        </div>
-->

        <input type="hidden" name="video" value="" id="video">
        <div class="control-group">
          <label class="control-label optional">Přiložené video</label>
          <div class="controls">
            <fieldset id="fieldset-video">
              <a href="" target="_blank">
                <span class="video-label">{{lesson.content.video}}</span>
              </a>
              <input type="button" class="video-delete hide" value="Odstranit">
              <input type="button" class="video-add" value="Přidat">
            </fieldset>
          </div>
        </div>
<!--
        <div class="control-group">
          <label class="control-label optional">Přiložené odkazy</label>
          <div class="controls">
            <fieldset id="fieldset-link">


              <input type="submit" name="link[0][add]" id="link-0-add" value="Přidat další" last-group="link-0"
                     class="contentedit-add"></fieldset>
          </div>
        </div>
-->
        <div class="control-group">
          <label class="control-label optional">Přílohy</label>
          <div class="controls">
            <fieldset id="fieldset-attachment">

              <content-attachments :lesson="lesson"></content-attachments>

            </fieldset>
          </div>
        </div>

        <div class="control-group">
          <label class="control-label optional">MP3</label>
          <lesson-files :lesson="lesson"></lesson-files>
        </div>

        <div class="form-actions">
          <input type="submit"  value="Uložit" class="btn btn-primary" @click.prevent="saveChanges">
        </div>

      </div>
    </div>
  </div>

  <pre>{{lesson}}</pre>
</template>

<style scoped>

</style>