<script>
import axios from "axios";
import {toast} from "vue3-toastify";
import { QuillEditor } from '@vueup/vue-quill'
import '@vueup/vue-quill/dist/vue-quill.snow.css';
export default {
  name: "Test",
  components: {QuillEditor},
  props: ['lesson'],
  data() {
    return {
      d: this.lesson.exercise.data_object,
    }
  },
  watch: {
    lesson() {
      this.d = this.lesson.exercise.data_object
    },
  },

  methods: {
    saveChanges(){
      axios.put(`/oj/exercises/${this.lesson.id}`, {
        exercise: this.lesson.exercise,
        data_object: this.d
      }).then(response =>{
        toast.success('Uloženo')
      }).catch(e => {
        console.log(e)
        toast.error(e.response.data.errors)
      })
      this.$emit('save', this.d)
    },

    numberToChar(number) {
      return String.fromCharCode(97 + number);
    },

    removeTestBlok(t){
      if (confirm('Opravdu smazat?')) {
        for (let i in this.d.test) {
          if (this.d.test[i] === t) {
            this.d.test.splice(i, 1)
          }
        }
      }
    },
    removePossibility(test, i){
      if (!confirm('Opravdu smazat?')) return
      test.possibilities.splice(i, 1)
    },
    appendPossibility(t){
      t.possibilities.push({text:'', isTrue:false})
    }
    ,
    addNewBlock() {
      this.d.test.push({
        question: '',
        possibilities: [
          {text: '', isTrue: false},
          {text: '', isTrue: false},
        ],
        explanation: ''
      })
    }
  }


}


/*
* "d": {
      "numberOfAnswers": "ONE_ANSWER",
      "displayPossibilities": "TEST_DISPLAY_POSSIBILITIES_INLINE",
      "timer": "900",
      "aboveText": "Vyberte správné možnosti.",
      "secondaryAboveText": "Choose the correct options.",
      "exerciseText": "",
      "belowText": "",
      "tab": "editor-tab",
      "test": [
        {
          "question": "1. Čím se živíš?",
          "possibilities": [
            {
              "text": "What do you like to do?",
              "isTrue": false
            },
            {
              "text": "What do you work for a living?",
              "isTrue": false
            },
            {
              "text": "What do you do for a living?",
              "isTrue": true
            }
          ]
        },
        {
          "question": "2. povolání",
          "possibilities": [
            {
              "text": "an employement",
              "isTrue": false
            },
            {
              "text": "an occupation",
              "isTrue": true
            },
            {
              "text": "a work",
              "isTrue": false
            },
            {
              "text": "to work",
              "isTrue": false
            }
          ]
        },
        * */

</script>

<template>

  <div class="well">
    <div class="row-fluid">
      <div class="span6">
        <div class="control-group"><label for="aboveText" class="control-label optional">Text nad cvičením</label>
          <div class="controls">
            <textarea v-model="lesson.exercise.aboveText"  name="aboveText" id="aboveText" rows="8" class="span12" cols="80">Je čas, abyste si nacvičili správný pravopis nových slov. Poslechněte si nahrávku a napište slova do křížovky.</textarea>
            <p class="help-block" style="color: #999;">Text, který se zobrazí nad cvičením. Měl by obsahovat zadání cvičení, kde studenta informujeme o tom, co od něho ve cvičení požadujeme a jak má postupovat.</p></div></div>
      </div>
      <div class="span6">
        <div class="control-group"><label for="secondaryAboveText" class="control-label optional">Sekundární text nad cvičením</label>
          <div class="controls">
            <textarea v-model="lesson.exercise.secondaryAboveText" name="secondaryAboveText" id="secondaryAboveText" rows="8" class="span12" cols="80"></textarea>
            <p class="help-block" style="color: #999;">Sekundární text nad cvičením - slouží zejména pro text ve vyučovaném jazyce</p></div></div>
      </div>
    </div>
  </div>



    <div class="control-group"><label for="exerciseText" class="control-label optional">Text cvičení</label>
      <div class="controls">
        <quill-editor v-model:content="lesson.exercise.exerciseText"
                      contentType="html"
        ></quill-editor>
        <p class="help-block" style="color: #999;">Text cvičení, například text konverzace se kterou dále student pracuje ve cvičení, apod.</p>
      </div>
    </div>




  <div class="control-group">
    <label for="numberOfAnswers" class="control-label optional">Počet odpovědí</label>
    <div class="controls">
      <select name="numberOfAnswers" id="numberOfAnswers" v-model="d.numberOfAnswers">
        <option value="ONE_ANSWER" label="ONE_ANSWER" >ONE_ANSWER</option>
        <option value="MANY_ANSWERS" label="MANY_ANSWERS">MANY_ANSWERS</option>
      </select>
    </div>
  </div>
  <div>
    <div class="control-group"><label for="displayPossibilities" class="control-label optional">Zobrazení odpovědí</label>
      <div class="controls">
        <select name="displayPossibilities" id="displayPossibilities" v-model="d.displayPossibilities">
          <option value="TEST_DISPLAY_POSSIBILITIES_INLINE" label="TEST_DISPLAY_POSSIBILITIES_INLINE" >TEST_DISPLAY_POSSIBILITIES_INLINE</option>
          <option value="TEST_DISPLAY_POSSIBILITIES_BLOCK" label="TEST_DISPLAY_POSSIBILITIES_BLOCK">TEST_DISPLAY_POSSIBILITIES_BLOCK</option>
        </select></div></div>
  </div>



  <div class="test-row" v-for="t in d.test">
    <div class="test-question">
      <input type="text" v-model="t.question"  class="form-control"/>
    </div>
    <div class="test-possibilities">
      <div class="test-possibility" v-for="(p,i) in t.possibilities">
        <span :class="`badge-pill badge test-ok ${p.isTrue ? 'badge-success':'badge-secondary'}`"
        @click="p.isTrue = !p.isTrue">{{numberToChar(i)}})</span>
        <input type="text" v-model="p.text"   class="test-possibility-text"/>
        <a class="color-delete remove-possibility" href="#" @click.prevent="removePossibility(t,i)"><span class="material-symbols-outlined">
delete
</span></a>
      </div>
      <button @click="appendPossibility(t)">+</button>
    </div>
    <label><strong>Vysvětlení</strong></label>
    <div class="test-explanation">
      <textarea v-model="t.explanation"  class="form-control"></textarea>
    </div>

    <a class="btn btn-danger test-remove" href="#" @click.prevent="removeTestBlok(t)"><i class="icon-remove icon-white"></i> Smazat</a>
    <hr>
  </div>

  <button @click.prevent="addNewBlock"> Přidat otázku</button>

  <div class="form-actions">
    <input type="submit" name="submit" id="submit" value="Uložit" class="btn btn btn-primary btn-large" @click.prevent="saveChanges">
  </div>

</template>

<style scoped lang="scss">
.test-possibility .badge{
  font-size:1em
}
.test-possibility{

}
.test-ok {
  cursor: pointer;
}
.oj-editor .test-possibility-text{
  margin-left: 10px;
  width: 80%;
  border-color: #eee;
}
.oj-editor .test-question{
  input[type="text"]{
    font-size: 1.2em;
  }
}

.remove-possibility{
  display: inline-block;
  margin-left: 10px;
 position: relative;
  top:7px;
  font-size: 90%;
}
</style>