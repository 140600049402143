<template>
    <div class="card card-body index p-0">
        <div class="top-button-group">
            <a href="/oj/courses" class="btn btn-light">Kurzy</a></div>

        <div class="d-flex">
            <div class="ml-auto p-2">
                <button class="btn btn-primary" @click.prevent="newCourse"><span class="material-icons mia">add_circle</span> Přidat kurz</button>
            </div>
        </div>


      <ul class="nav">
        <li class="nav-item" v-for="t in targets">
          <a :class="`nav-link ${ t == target  ? 'active': ''}`" href="#" @click.prevent="filterByTarget(t)" :key="t">{{ t }}</a>
        </li>


      </ul>

        <table class="table">
            <thead>
            <tr>
              <th></th>
                <th>Název</th>
                <th>Trh/Zdrojový jazyk</th>
                <th>Cílový jazyk</th>
                <th>Stav</th>
                <th></th>
            </tr>
            </thead>
            <tr v-for="l in courses">
              <td>
                {{l.id}}
              </td>
                <td>
                  <a :href="`/oj/courses/${l.id}`">{{l.name}}</a>
                </td>
                <td>{{l.target}}</td>
                <td>{{l.type}}</td>

                <td>{{l.status}}</td>
                <td><a :href="`/oj/courses/${l.id}`"><span class="material-icons-outlined">mode_edit_outline</span></a></td>
            </tr>

        </table>

    </div>

</template>

<script>
import axios from "axios";

export default {
  name: "courses",
  components: {},
  data(){
    return {
        courses: [],
        course:{},
      targets:[],
      target:''
    }
  },

  mounted() {
    this.loadCollections()
    this.loadCourses()
  },
  methods:{
    filterByTarget(t){
      console.log(';filterByTarget',t)
      this.target = t
      this.loadCourses()

    },
    loadCollections(){
        axios.get('/oj/courses/collections.json',{ params:{}})
            .then(response => {
                this.targets = response.data.targets
            })
            .catch(error => {
                console.log(error)
            })
    },


      onCourseUpdated(){
          this.loadCourses()
      },
      async loadCourses(){
        const r2 = await axios.get('/oj/courses.json',{ params:{target: this.target}})
        this.courses = r2.data.items
        //for(let i in this.courses){
        //    if (this.courses[i].status==null) this.courses[i].kk_status=''
        //}

      },
      editCourse(l){
          this.course = l
          //this.$refs.modalCourse.showModal()
      },
      newCourse(){
          this.course = {}
          //this.$refs.modalCourse.showModal()
      }

  }
}
</script>

<style scoped>

.user-ico{
    width:30px;
    border-radius: 14px;
}

a.active{
  color: black;
  border-top: 1px solid black;
}
</style>