<script>

import Popper from "vue3-popper";

export default {
  name: "SortWords",
  props: ['lesson'],
  components: { Popper},
  data() {
    return {
      d: this.lesson.exercise.data_object,
      new_line_prepare: '',
      new_line_id: 0
    }
  },
  watch: {
    lesson() {
      this.d = this.lesson.exercise.data_object
    },
  },
  methods: {
    onCEChange(t, el){
      const element = event.target;
      const htmlContent = element.innerHTML.replaceAll("<br>", '');

      console.log('onCEChange', t, htmlContent)
      if (t.content_tmp != htmlContent){
        t.content_tmp = htmlContent;
      }
    },

    saveChanges(){
      for(let i in this.d.lines){
        let l = this.d.lines[i]
        for(let j in l.tokens){
          let t = l.tokens[j]
          if (t.content_tmp) {
            console.log('saving', t.content_tmp)
            t.content = t.content_tmp
            delete t.content_tmp
          }
        }
      }
    },

    addLine(){
      const id = Object.keys(this.d.lines).length + 1;
      console.log("addLine", id)
      this.new_line_id = id
      this.d.lines[id]= {
        id: id,
        line: id + '. ',
        tokens: [{
              "id": 1,
              "type": "text",
              "content":  id +". "
            },{
          id:2,
          type:'new',
          content:''
        }

        ]
      }
    },
    prependToken(line, current_token){
      const id = Object.keys(line.tokens).length + 1;
      console.log("appendToken", id)
      let current_token_position = line.tokens.indexOf(current_token)
      line.tokens.splice(current_token_position, 0,  {
        "id": id,
        "type": "word",
        "content": " "
      })
    },
    appendToken(line, current_token){
      const id = Object.keys(line.tokens).length + 1;
      console.log("appendToken", id)
      let current_token_position = line.tokens.indexOf(current_token)
      line.tokens.splice(current_token_position+1, 0,  {
        "id": id,
        "type": "word",
        "content": " "
      })
    },
    expandNewLine() {
      const id = this.new_line_id
      console.log("expandNewLine", id)
      let arr = this.new_line_prepare.replaceAll("  ", " ").split(" ")

      // vymazat token typu new
      this.d.lines[id].tokens = this.d.lines[id].tokens.filter(t => t.type != 'new')

      let max_id = this.d.lines[id].tokens.length+1
      for (let i in arr) {
        let word = arr[i]

        this.d.lines[id].tokens.push({
          "id": parseInt(i) + max_id,
          "type": "word",
          "content": word
        })
      }

      this.new_line_prepare = ''
    }
  },

}
/*
* example d: "lines": {
        "1": {
          "id": 1,
          "line": "1. <span>What</span> <span>do</span> <span>you</span> <span>do?</span>",
          "tokens": [
            {
              "id": 1,
              "type": "text",
              "content": "1."
            },
            {
              "id": 2,
              "type": "word",
              "content": "What"
            },
            {
              "id": 4,
              "type": "word",
              "content": "do"
            },
            {
              "id": 6,
              "type": "word",
              "content": "you"
            },
            {
              "id": 8,
              "type": "word",
              "content": "do?"
            }
          ]
        },
        "2": {
          "id": 2,
          "line": "2. <span>What</span> <span>is</span> <span>your</span> <span>job?</span>",
          "tokens": [ ... ]
* */

</script>

<template>

  <div class="lines-box">
    <div v-for="l in d.lines" class="tokens">
      <template v-for="t in l.tokens">
        <span v-if="t.type=='new'"><input type="text" v-model="new_line_prepare"/><button @click.prevent="expandNewLine" >OK</button></span>
        <Popper arrow  class="light" v-else>
          <span  :class="t.type" contenteditable spellcheck="false" v-html="t.content" @keydown="onCEChange(t, $event )" @keyup="onCEChange(t, $event )"></span>

          <template #content>
            <b>{{t.content_tmp || t.content}}</b>
            <div>deaktivovat</div>
            <div>zapnout jako aktivní</div>
            <div><a href="#" @click.prevent="appendToken(l, t)">nové slovo za</a></div>
            <div><a href="#" @click.prevent="prependToken(l,t)">nové slovo před</a></div>
            <div>smazat</div>
          </template>
        </Popper>
      </template>
    </div>
    <a href=# @click.prevent="addLine">+ pridat radek</a>
  </div>



  <div class="form-actions">
    <input type="submit" name="submit" id="submit" value="Uložit" class="btn btn btn-primary btn-large" @click.prevent="saveChanges">
  </div>

  <pre>
---
{{d.lines}}
</pre>
</template>



<style scoped>


</style>