<script>
import axios from 'axios'
import ExerciseOptions from "@/components/oj/courses/exercise-options.vue";
import NewLesson from "@/components/oj/courses/new-lesson.vue";

import AssignTextToImage from "@/components/oj/courses/exercise/AssignTextToImage.vue";
import AssignToGroups from "@/components/oj/courses/exercise/AssignToGroups.vue";
import Crossword from "@/components/oj/courses/exercise/Crossword.vue";
import FillUp from "@/components/oj/courses/exercise/FillUp.vue";
import NotInGroup from "@/components/oj/courses/exercise/NotInGroup.vue";
import SortSentences from "@/components/oj/courses/exercise/SortSentences.vue";
import SortWords from "@/components/oj/courses/exercise/SortWords.vue";
import TrueFalse from "@/components/oj/courses/exercise/TrueFalse.vue";
import ContentOptions from "@/components/oj/courses/content-options.vue";
import Test from "@/components/oj/courses/exercise/Test.vue";


export default {
  name: "lessons",
  props:['course'],
  components: {ExerciseOptions, NewLesson, AssignTextToImage, AssignToGroups, Crossword, FillUp, NotInGroup, SortSentences, SortWords, TrueFalse, ContentOptions, Test},
  data(){
    return {
      lessons:[],
      tab:'options',
      week:{},
      currentLesson:{}
    }
  },

  watch:{
    '$route.params.wid': 'loadLessons',

    week(){
      console.log('week changed')

    }
  },
  methods:{
    loadWeek(wid){
      if (!wid) return
      axios.get('/oj/weeks/'+wid+'.json').then(r=>{
        this.week = r.data
      })
    },
    loadLessons(){
      let wid = this.$route.params.wid

      this.loadWeek(wid)



      if (!wid) return
      axios.get('/oj/weeks/'+wid+'/lessons.json').then(r=>{
        this.lessons = r.data.lessons
      })
    },
    editExercise(l){
      this.currentLesson = l
      console.log('edit exercise', l)
    },
    editContent(l){
      this.currentLesson = l
    },

    addNewLesson(){
      this.currentLesson = {
        lesson_discriminator: ''
      }

      this.$refs.modalNewLesson.showModal()

    },


    onLessonTypeSelected(lesson){

      this.currentLesson = lesson
      this.$refs.modalNewLesson.hideModal()
      this.lessons.push(lesson)
    }



  },
  mounted(){
    this.loadLessons()
  }


}
</script>

<template>
week <h3>{{week.name}}</h3>

  <div class="row">
    <div class="col-md-3">
    <button @click="addNewLesson">nova lekce/obsah</button>
  <table class="lessons-table">
    <tr v-for="l in lessons" :class="`${l.is_active ? 'active': 'inactive'}  ${l.lesson_discriminator}`">
      <td>
        <a v-if="l.lesson_discriminator == 'exercise'" href="#" @click.prevent="editExercise(l)">{{l.name}}</a>
        <a v-if="l.lesson_discriminator == 'content'" href="#" @click.prevent="editContent(l)">{{l.name}}</a>
      </td>
      <td>{{l.exercise ? l.exercise.exercise_type_class :''}}</td>
    </tr>
  </table>


      week: {{week}}

    </div>

    <div class="col-md-9" v-if="currentLesson.lesson_discriminator == 'content'">
      <ContentOptions :lesson="currentLesson"></ContentOptions>
    </div>


    <div class="col-md-8" v-if="currentLesson.lesson_discriminator == 'exercise'">
        <ul class="nav nav-tabs">
                <li :class="tab === 'options' ? 'active':''"><a data-toggle="tab" href="#content-editor" @click.prevent="tab='options'">Základní nastavení</a></li>
                <li :class="tab === 'ex' ? 'active':''"><a data-toggle="tab" href="#excersise-editor" @click.prevent="tab='ex'">Obsah cvičení</a></li>

        </ul>
      <div v-if="tab === 'options'">
        <exercise-options  :lesson="currentLesson"></exercise-options>
        <pre>
          {{currentLesson}}
        </pre>
      </div>
      <div v-if="tab === 'ex'">
        <!-- Lekce nastaveni {{currentLesson.exercise.exercise_type_class}}-->

        <AssignTextToImage v-if="currentLesson.exercise.exercise_type_class == 'AssignTextToImage'" :lesson="currentLesson"></AssignTextToImage>
        <AssignToGroups v-if="currentLesson.exercise.exercise_type_class == 'AssignToGroups'" :lesson="currentLesson"></AssignToGroups>
        <Crossword v-if="currentLesson.exercise.exercise_type_class == 'Crossword'" :lesson="currentLesson"></Crossword>
        <FillUp v-if="currentLesson.exercise.exercise_type_class == 'FillUp'" :lesson="currentLesson"></FillUp>
        <NotInGroup v-if="currentLesson.exercise.exercise_type_class == 'NotInGroup'" :lesson="currentLesson"></NotInGroup>
        <SortSentences v-if="currentLesson.exercise.exercise_type_class == 'SortSentences'" :lesson="currentLesson"></SortSentences>
        <SortWords v-if="currentLesson.exercise.exercise_type_class == 'SortWords'" :lesson="currentLesson"></SortWords>
        <TrueFalse v-if="currentLesson.exercise.exercise_type_class == 'TrueFalse'" :lesson="currentLesson"></TrueFalse>
        <Test v-if="currentLesson.exercise.exercise_type_class == 'Test'" :lesson="currentLesson"></Test>
      </div>
    </div>

  </div>

  <new-lesson :week="week"  ref="modalNewLesson" @typeSelected="onLessonTypeSelected"></new-lesson>

</template>

<style scoped>

.inactive {
  color: silver;
}
</style>