<script>

import axios from 'axios';
export default {
  name: "lesson-files",
  props:['lesson'],
  data() {
    return {
      uploadProgress: ''
    };
  },
  mounted() {
    this.normalizeBool()
  },
  watch: {
    lesson: {
      handler: function () {
        this.normalizeBool()
      },
      deep: true
    }
  },

  methods: {
    normalizeBool(){
      this.lesson.lesson_files.forEach(f=>{
        f.autoplay = f.autoplay === '1' || f.autoplay === true || f.autoplay === 1
      })
    },
    handleFileChange(event) {
      const files = event.target.files;
      if (files.length > 0) {
        this.uploadFiles(files);
      }
    },
    selectFiles() {
      document.getElementById('mp3-file-input').click();
    },

    removeFile(f) {
      axios.delete('/oj/lesson-files/' + f.id).then(r => {
        this.lesson.lesson_files = this.lesson.lesson_files.filter(lf => lf.id !== f.id);
      });

    },

    uploadFiles(files) {

      const formData = new FormData();
      formData.append('lesson_id', this.lesson.id);
      for (let i = 0; i < files.length; i++) {
        const file = files[i];
        formData.append('file[]', file);
      }

      const xhr = new XMLHttpRequest();
      xhr.open('POST', '/oj/upload-lesson-file', true);

      xhr.upload.onprogress = (event) => {
        if (event.lengthComputable) {
          const percentComplete = Math.round((event.loaded / event.total) * 100);
          this.uploadProgress = `Upload progress: ${percentComplete}%`;
        }
      };

      xhr.onload = () => {
        if (xhr.status === 200) {
          this.uploadProgress = 'Upload complete!';

          const response = JSON.parse(xhr.responseText);
          const lf = response.lesson_files;
          console.log('response', lf);
          for (let i = 0; i < lf.length; i++) {
            // kontrola id ciz uz existuje
            if (this.lesson.lesson_files.find(l => l.id === lf[i].id)) {
              continue;
            }
            this.lesson.lesson_files.push(lf[i]);
          }


        } else {
          this.uploadProgress = 'Upload failed!';
        }
      };
      const csfr_token = document.querySelector('meta[name="csrf-token"]').getAttribute('content')
      xhr.setRequestHeader('X-CSRF-Token', csfr_token);

      xhr.send(formData);
    }
  }







}
</script>

<template>

  <div class="controls">
    <table id="mp3-list" class="table table-striped table-bordered table-condensed">
      <thead>
      <tr>
        <th>Soubor</th>
        <th>Popisek</th>
        <th>Text</th>
        <th>Spustit automaticky</th>
        <th>Akce</th>
      </tr>
      </thead>
      <tbody>
      <tr v-for="f in lesson.lesson_files">
        <td><a href="">{{f.original_name}}</a></td>
        <td>
          <input type="text" name="mp3_label[__lessonFileId]" v-model="f.label">
        </td>
        <td>
                  <textarea class="input-xxlarge" rows="2" cols="30" name="mp3_text[__lessonFileId]"
                            v-model="f.text"
                            ></textarea>
        </td>
        <td>
          <input type="checkbox" name="mp3_autoplay[__lessonFileId]" v-model="f.autoplay">
        </td>
        <td>
          <a class="mp3-remove-btn" href="/wizard/remove-file/id/__lessonFileId" @click.prevent="removeFile(f)">Smazat</a>
        </td>
      </tr>

      </tbody>
    </table>
    <div id="mp3-container" style="position: relative;">

<input id="mp3-up-btn" type="button" value="Nahrát soubor" @click="selectFiles" style="position: relative; z-index: 0;">

      <span id="mp3-progress">{{ uploadProgress }}</span>
      <input id="mp3-file-input"
             type="file"
             xxaccept="audio/mpeg"
             multiple style="display: none;"
             @change="handleFileChange">


    </div>
    <span class="help-block">Povolené přípony: mp3</span>
  </div>
</template>

<style scoped>

</style>