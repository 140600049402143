<script>
import {VueFinalModal} from "vue-final-modal";
import ModalKkMixin from "@/components/conversation-club/modal-kk-mixin.js";
import ImagePicker from "@/components/oj/image-picker.vue";
import axios from "axios";
import {toast} from "vue3-toastify";
export default {
  name: "chapter-form",
  props:['chapter'],
  mixins: [ModalKkMixin],
  components: {VueFinalModal, ImagePicker},
  data(){
    return {

      avatar: null
    }
  },
  mounted() {

  },
  watch:{
    chapter(){
      this.imgsrc = `https://editor.onlinejazyky.cz${this.chapter.image}`
    }
  },

  methods:{
    onImageChange(f){
      this.avatar = f
    },

    handleSaveChanges() {
      const formData = new FormData()


      formData.append('chapter[name]', this.chapter.name)

      if (this.avatar) {
        formData.append("chapter[image]", this.avatar, this.avatar.name)
      }
      if (this.chapter.id) {
        axios.put(`/oj/chapters/${this.chapter.id}`, formData).then(response => {

          this.$emit('chapterUpdated')
          this.hideModal()
          toast.success('Kapitola byla upravena')
        }).catch(e=>{

          toast.error(e.response.data.errors)

        })
      }else{
        axios.post(`/oj/courses/${this.chapter.course_id}/chapters`, formData).then(response => {

          this.$emit('chapterUpdated')
          this.hideModal()
          toast.success('Kapitola byla vytvorena')
        }).catch(e=>{

          toast.error(e.response.data.errors)

        })
      }


    }

  }
}
</script>

<template>
  <vue-final-modal v-model="showM" classes="modal-container" content-class="modal-content-f w800">
    <div class="modal-header">
      <b>Kapitola</b> {{this.chapter.id}}
      <button type="button" class="close" @click.prevent="hideModal" aria-label="Close">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>

    <div class="modal__content">
      <div class="form-group">
        <label>Nazev</label>
        <input type="text" v-model="chapter.name" class="form-control"/>
      </div>



      <div class="form-group">
        <label>Popis</label>
        <textarea v-model="chapter.description" class="form-control"></textarea>
      </div>



      <div class="form-group">
        <label>Obrazek</label>
        <image-picker @imageChange="onImageChange" :src="chapter.image"></image-picker>

      </div>
    </div>
    <div class="modal-footer">
      <button @click="handleSaveChanges">Uloz zmeny</button>
    </div>

  </vue-final-modal>
</template>

<style scoped>


</style>