<script>
import axios from 'axios';
import ModalKkMixin from '@/components/conversation-club/modal-kk-mixin.js';
export default {
  name: "exercise-type",
  mixins: [ModalKkMixin],
  props:['exercise_type', 'week'],
  data(){
    return {
      tab:'obsah',
      exercise_types:[],
      content_types:[],
    }
  },
  mounted(){
    this.loadTypes();
  },
  methods:{
    loadTypes(){
      axios.get('/oj/lessons/content_types.json')
      .then(response => {
        this.exercise_types = response.data.exercise_types;
        this.content_types= response.data.content_types;
      })
      .catch(error => {
        console.log(error);
      });
    },


    createContent(t){
      this.hideModal();
      const lesson ={
        week_id: this.week.id,
        lesson_discriminator: 'content',
        content_type: t.id,
            "name": "",
            "is_active": true,
            "modified": null,
            "avatar_text": "",
            "avatar_text_mode": "",
            "transcript": null,
            "author_id": 0,
            "secondary_name": "",
            "order_in_week": 0,
            "milestone": 0,
            "milestone_text": "",
            "position": 1,
            "evaluate": 1,
            "trial_only": 0,
        content:{
          type_id: t.id,
        },
        lesson_files:[],
        content_attachments: []
      }
      this.$emit('typeSelected', lesson);
    },

    createExercise(t){
      this.hideModal();
      const lesson = {
        week_id: this.week.id,
        lesson_discriminator: 'exercise',
        exercise_type: t.id,
        "name": "",
        "is_active": true,
        "modified": null,
        "avatar_text": "",
        "avatar_text_mode": "",
        "transcript": null,
        "author_id": 0,
        "secondary_name": "",
        "order_in_week": 0,
        "milestone": 0,
        "milestone_text": "",
        "position": 1,
        "evaluate": 1,
        "trial_only": 0,
        exercise: this.initial_exercise(t),
      }
      this.$emit('typeSelected', lesson);
    },


    initial_exercise(t){
      const e = {
        exercise_type: t,
        exercise_type_class: t.class_name,
        data:'',
        data_object:{
        },
        "description": "",
        "level": "EXERCISE_LEVEL_A1",
        "deleted": false,
        "imported": false,
        "category": "EXERCISE_CATEGORY_GRAMMAR",
        "course_type": "CZECH",
        "course_level": "COURSE_LEVEL_A1",
        "grammar": null,
        "aboveText": "",
        "belowText": "",
        "rightText": null,
        "exerciseText": "",
        "secondaryAboveText": "",
        "is_mandatory": true
      }

      if (t.class_name == 'Crossword'){
        e.data_object ={
          rows: 0,
          cols: 0,
          "grid": [],
          "question": [],
          "timer": "0",
        }
      }
      if (t.class_name == 'AssignTextToImage'){
        e.data_object ={
          "images": {},
          "timer": "0",
        }
      }
      /*   <AssignTextToImage v-if="currentLesson.exercise.exercise_type_class == 'AssignTextToImage'" :lesson="currentLesson"></AssignTextToImage>
        <AssignToGroups v-if="currentLesson.exercise.exercise_type_class == 'AssignToGroups'" :lesson="currentLesson"></AssignToGroups>
        <Crossword v-if="currentLesson.exercise.exercise_type_class == 'Crossword'" :lesson="currentLesson"></Crossword>
        <FillUp v-if="currentLesson.exercise.exercise_type_class == 'FillUp'" :lesson="currentLesson"></FillUp>
        <NotInGroup v-if="currentLesson.exercise.exercise_type_class == 'NotInGroup'" :lesson="currentLesson"></NotInGroup>
        <SortSentences v-if="currentLesson.exercise.exercise_type_class == 'SortSentences'" :lesson="currentLesson"></SortSentences>
        <SortWords v-if="currentLesson.exercise.exercise_type_class == 'SortWords'" :lesson="currentLesson"></SortWords>
        <TrueFalse v-if="currentLesson.exercise.exercise_type_class == 'TrueFalse'" :lesson="currentLesson"></TrueFalse>
      </div>*/

      if (t.class_name == 'FillUp'){
        e.data_object ={
          "fillups": {
            "1": {
              "id": 1,
              "tokens": [
                {
                  "id": 1,
                  "type": "text",
                  "content": "1. You  "
                }
                ]
            }
          }
        }
      }

      if (t.class_name == 'NotInGroup'){
        e.data_object ={
          "timer": "0",
          "aboveText": "",
          "secondaryAboveText": "",
          "exerciseText": "",
          "belowText": "",
          "tab": "editor-tab",
          "word": [
            [
              "text 1",
              "text 2"
            ],

          ],
          "wrong": [
            "1"
          ]
        }
      }

      if (t.class_name == 'SortSentences'){
        e.data_object ={
          "sentences": [
            "...",
            "...",
          ]
        }
      }
      if (t.class_name == 'SortWords'){
        e.data_object = {
          "lines": {
             "1": {
                "id": 1,
                "line": "",
               "tokens": [
                  {
                    "id": 1,
                    "type": "text",
                    "content": "1."
                  },
                  {
                    "id": 2,
                    "type": "word",
                    "content": "..."
                  }
               ]
             }
          }
        }
      }

      if (t.class_name== 'Test'){
        e.data_object= {
          "numberOfAnswers": "ONE_ANSWER",
          "displayPossibilities": "TEST_DISPLAY_POSSIBILITIES_INLINE",
          "timer": "900",
          "aboveText": "Vyberte správné možnosti.",
          "secondaryAboveText": "Choose the correct options.",
          "exerciseText": "",
          "belowText": "",
          "tab": "editor-tab",
          "test": [
            {
              "question": "",
              "possibilities": [
                {
                  "text": "",
                  "isTrue": false
                },
                {
                  "text": "",
                  "isTrue": false
                },

              ]
            }
          ]
        }
      }


      return e
    }







  }
}
</script>

<template>

  <vue-final-modal v-model="showM" classes="modal-container" content-class="modal-content-f w90">
    <div class="modal-header">
      <b>Novy obsah nebo cviceni</b>
      <button type="button" class="close" @click.prevent="hideModal" aria-label="Close">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>

    <div class="modal__content" id="wizardContentTypeSelect">


      <ul class="nav nav-tabs">
        <li :class="tab === 'obsah' ? 'active':''"><a data-toggle="tab" href="#content-editor" @click.prevent="tab='obsah'">Obsah</a></li>
        <li :class="tab === 'cv' ? 'active':''"><a data-toggle="tab" href="#excersise-editor" @click.prevent="tab='cv'">Cvičení</a></li>
        <li :class="tab === 'slov' ? 'active':''"><a data-toggle="tab" href="#vocabulary-editor" @click.prevent="tab='slov'">Slovíčka</a></li>
        <li :class="tab === 'fraze' ? 'active':''"><a data-toggle="tab" href="#phrase-editor" @click.prevent="tab='fraze'">Fráze</a></li>
      </ul>

      <div v-if="tab=='obsah'">

      <ul class="thumbnails" >
        <li class="span4" v-for="t in content_types">
          <div class="thumbnail">
            <div class="caption">
              <h4>{{t.name}}</h4>
              <p class="description">{{ t.description }}</p>
              <p class="actions">
                <a href="#" class="btn3 btn-primary" @click.prevent="createContent(t)">               Vybrat            </a>
              </p>
            </div>
          </div>
        </li>
      </ul>

      </div>
      <div v-if="tab=='cv'">

        <ul class="thumbnails" >
          <li class="span4" v-for="t in exercise_types">
            <div class="thumbnail">
              <div class="caption">
                <h4>{{t.name}}</h4>
                <p class="description">{{ t.description }}</p>
                <p class="actions">
                  <a href="#" class="btn3 btn-primary" @click.prevent="createExercise(t)">               Vybrat            </a>
                </p>
              </div>
            </div>
          </li>
        </ul>

      </div>

    </div>
  </vue-final-modal>

</template>

<style scoped>
.span4{
    width: 31%;
    margin-right: 2%;
    float: left;
    list-style: none;

}

.thumbnail .caption {
    padding: 9px;
    color: #555;
  text-align: center;

}

#wizardContentTypeSelect .thumbnails li {
    margin: 0 10px 10px 0;
}
#wizardContentTypeSelect .thumbnails .thumbnail h4 {
    text-align: center;
    margin: 0 0 5px 0;
}
#wizardContentTypeSelect .thumbnails .thumbnail {

    height: 150px;
    padding: 10px;
    background-color: #FFFFFF;
    border: 1px solid #DDDDDD;

}




</style>