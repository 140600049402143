<script>

import { QuillEditor } from '@vueup/vue-quill'


export default {
  name: "TrueFalse",
  props: ['lesson'],
  components: {QuillEditor},
  data() {
    return {
      ttt:'',
      content:'',
      d: this.lesson.exercise.data_object,
      btns_key: [0,1,2],

    }
  },
  mounted() {
    this.prepare_content()
  },
  watch: {
    lesson() {
      this.d = this.lesson.exercise.data_object
      this.prepare_content()
    },
  },
  methods: {
    prepare_content(){
      this.btns_key = this.d.question.map((q, i) => i*2200)
      if (!this.d.explanationTrue) this.d.explanationTrue = []
      if (!this.d.explanationFalse) this.d.explanationFalse = []
    },

    is_true(index){
      return this.d.true[index] === 1
    },
    setItemTrue(i){
      this.d.true[i] = 1
      this.btns_key[i]++
    },
    setItemFalse(i){
      this.d.true[i] = 0
      this.btns_key[i]++
    },

    removeItem(index){
      this.d.question.splice(index, 1)
      this.d.explanationTrue.splice(index, 1)
      this.d.explanationFalse.splice(index, 1)
      this.d.true.splice(index, 1)
      this.btns_key.splice(index, 1)
    },

    addRow(){
      this.d.question.push('')
      this.d.explanationTrue.push('')
      this.d.explanationFalse.push('')
      this.d.true.push(0)
      this.btns_key.push(this.btns_key.length * 2200)
    }

  }

}
/*  "d": {
      "timer": "0",
      "aboveText": "",
      "secondaryAboveText": "",
      "exerciseText": "<p>text <span class=\"strong\">cviceni</span> huuu</p>",
      "grammar": "",
      "belowText": "",
      "tab": "editor-tab",
      "rightText": "<p><img class=\"img33\" src=\"/galleries/116517910_a23086fb38_z.jpg\" alt=\"to je image descr\" /></p>",
      "question": [
        "prasatko papa rado skace v louzich",
        "naky druhy tvrzeni",
        "ewd ewd we wrfgreg egter g"
      ],
      "explanationTrue": [
        "protoze spravne",
        "hahahkwkwe",
        "alexandr veliky"
      ],
      "explanationFalse": [
        "protoze neco spatne nevim",
        "wehew wedewdikhwdwe",
        "protoze kamala je krava"
      ],
      "true": [
        1,
        0,
        0
      ]
    },*/


</script>

<template>

  <div class="well">
    <div class="row-fluid">
      <div class="span6">
        <div class="control-group"><label for="aboveText" class="control-label optional">Text nad cvičením</label>
          <div class="controls">
            <textarea v-model="lesson.exercise.aboveText"  name="aboveText" id="aboveText" rows="8" class="span12" cols="80"></textarea>
            <p class="help-block" style="color: #999;">Text, který se zobrazí nad cvičením. Měl by obsahovat zadání cvičení, kde studenta informujeme o tom, co od něho ve cvičení požadujeme a jak má postupovat.</p>
          </div>
        </div>
      </div>
      <div class="span6">
        <div class="control-group"><label for="secondaryAboveText" class="control-label optional">Sekundární text nad cvičením</label>
          <div class="controls">
            <textarea v-model="lesson.exercise.secondaryAboveText" name="secondaryAboveText" id="secondaryAboveText" rows="8" class="span12" cols="80"></textarea>
            <p class="help-block" style="color: #999;">Sekundární text nad cvičením - slouží zejména pro text ve vyučovaném jazyce</p>
          </div>
        </div>
      </div>
    </div>
  </div>


    <div class="control-group"><label for="exerciseText" class="control-label optional">Text cvičení</label>
      <div class="controls">
        <quill-editor v-model:content="lesson.exercise.exerciseText"
                      contentType="html"
                      ></quill-editor>
        <p class="help-block" style="color: #999;">Text cvičení, například text konverzace se kterou dále student pracuje ve cvičení, apod.</p>
      </div>
    </div>






  <table class="w-100">
  <tbody class="row-container ">
    <tr v-for="(q, i) in d.question">
      <td style="vertical-align: top">
        <label><strong>Tvrzení</strong></label>
        <input class="question form-control" type="text" v-model="d.question[i]"><br>

        <div class="btn-group" data-toggle="buttons-radio" :key="btns_key[i]">
          <a href="#" @click.prevent="setItemTrue(i)"  :class="`btn btn-success true ${is_true(i) ? 'active': ''} `">True</a>
          <a href="#" @click.prevent="setItemFalse(i)" :class="`btn btn-danger false ${!is_true(i) ? 'active': ''}`">False</a>
          <a href="#" @click.prevent="removeItem(i)"  class="remove btn btn-danger"><i class="icon-remove icon-white"></i></a>
        </div>
      </td>
      <td>
        <div class="">
          <label><strong>Proč správně</strong></label>
          <input class="explanation-true form-control" type="text" v-model="d.explanationTrue[i]" v-if="d.explanationTrue">
        </div>
        <div class="">
          <label><strong>Proč špatně</strong></label>
          <input class="explanation-false form-control" type="text" v-model="d.explanationFalse[i]" v-if="d.explanationFalse">
        </div>
      </td>
    </tr>
   </tbody>
</table>

  <button @click.prevent="addRow">Přidat řádek</button>


</template>

<style lang="scss" >
#app-oj-course {
  .explanation-false {
    border-color: red;

  }

  .explanation-true {
    border-color: green;
  }
}
.btn.active, .btn:active {
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.6) inset, 0 1px 2px rgba(0, 0, 0, 0.05);



}
</style>