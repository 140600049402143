<script>


import axios from "axios";

export default {
  name: "app-product-skumapping",
  props: ['product'],

  data: function() {
    return {
      mappings: [],
      products: [],
      selected_product: {},
      kod: '',
      months: 0
    }
  },
  mounted() {

    this.loadMappings()
  },
  methods:{

    removeMapping(mid){
      axios.delete(`/products/${this.product}/skumappings/${mid}`).then((response) => {
        this.loadMappings()
      })
    },
    loadMappings(){
      axios.get(`/products/${this.product}/skumappings`).then((response) => {
        this.mappings = response.data
      })
    },
    addMapping(){
      axios.post(`/products/${this.product}/skumappings`, {
        foreign_sku: this.kod,
        subscription_months: this.months
      }).then((response) => {
        this.loadMappings()
        this.kod=''
        this.months=0
      })
    }

  }

}
</script>

<template>
  <div class="well">
    <h4>SKU Mapping</h4>

    <table class="table table-condensed w-50">
      <thead>
        <tr>
          <th>SKU ve woo / Kód ve Fapi</th>
          <th>Délka předplatného v měsících</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        <tr>
                  <td>
                  <input type="text" v-model="kod" class="form-control">
                  </td>
                  <td>
                    <select v-model="months" class="form-control">
                      <option value="0">Trvale</option>
                      <option value="1"> 1 </option>
                      <option value="3"> 3 </option>
                      <option value="6"> 6 </option>
                      <option value="12"> 12 </option>
                    </select>
                  </td>
                  <td>
                    <button type="button" @click.prevent="addMapping" class="btn btn-primary btn-sm">Přidat</button>
                  </td>
        </tr>
        <tr v-for="m in mappings">
          <td>{{m.foreign_sku}}</td>
          <td>{{m.subscription_months > 0 ? m.subscription_months : 'trvale'}}</td>
          <td>
            <button type="button" @click.prevent="removeMapping(m.id)" class="btn btn-danger btn-sm">Smazat</button>
          </td>
        </tr>
      </tbody>
    </table>

  </div>
</template>

<style scoped>

</style>