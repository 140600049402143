<script>

import { QuillEditor } from '@vueup/vue-quill'
export default {
  name: "AssignTextToImage" ,
  components: {QuillEditor},
  props: ['lesson'],
  data() {
    return {
      d: this.lesson.exercise.data_object,
      current_item: null,
      new_images: {},
    }
  },
  watch:{
    lesson(){
      this.d = this.lesson.exercise.data_object
    }
  },

  methods: {
    addItem() {
      //# dalsi id podle nalezenych id
      let new_id = 1
      if (Object.keys(this.d.images).length >0 ) {
        new_id = Math.max(...Object.keys(this.d.images).map(i => parseInt(i))) + 1
      }
      this.d.images[new_id]={
        id: new_id,
        image: '',
        text: [''],
        wrong: ['']
      }
    },
    selectImage(item){
      console.log('selecting image', item)
      this.current_item = item
      this.handleClickF();


    },
    removeItem(item){
      for(let i in this.d.images){
        if(this.d.images[i].id === item.id){
          delete this.d.images[i]
        }
      }

    },


    handleClickF(){
      this.$refs.pinimagefile.click()
    },
    handleUploadImage(e) {
      let f = e.target.files[0]
      this.new_images[this.current_item.id] = {  f: f, src: URL.createObjectURL(f)  }
    },
    new_image_src(item){
      if(this.new_images[item.id]){
        return this.new_images[item.id].src
      }
      return `https://editor.onlinejazyky.cz${item.image}`
    }



  }


}
/*
* {
  "images": {
    "1": {
      "id": 1,
      "image": "/galleries/users/55/Pixmac000053768945.jpg",
      "text": [
        "sushi with tuna and salmon"
      ],
      "wrong": [
        "sushi with pork"
      ]
    },
    "2": {
      "id": 2,
      "image": "/galleries/users/55/Pixmac000055021005.jpg",
      "text": [
        "crispy fried chicken"
      ],
      "wrong": [
        "crispy baked chicken"
      ]
    },
    "3": {*/

</script>

<template>
  <div class="well">
    <div class="row-fluid">
      <div class="span6">
        <div class="control-group"><label for="aboveText" class="control-label optional">Text nad cvičením</label>
          <div class="controls">
            <textarea v-model="lesson.exercise.aboveText"  name="aboveText" id="aboveText" rows="8" class="span12" cols="80">Je čas, abyste si nacvičili správný pravopis nových slov. Poslechněte si nahrávku a napište slova do křížovky.</textarea>
            <p class="help-block" style="color: #999;">Text, který se zobrazí nad cvičením. Měl by obsahovat zadání cvičení, kde studenta informujeme o tom, co od něho ve cvičení požadujeme a jak má postupovat.</p></div></div>
      </div>
      <div class="span6">
        <div class="control-group"><label for="secondaryAboveText" class="control-label optional">Sekundární text nad cvičením</label>
          <div class="controls">
            <textarea v-model="lesson.exercise.secondaryAboveText" name="secondaryAboveText" id="secondaryAboveText" rows="8" class="span12" cols="80"></textarea>
            <p class="help-block" style="color: #999;">Sekundární text nad cvičením - slouží zejména pro text ve vyučovaném jazyce</p></div></div>
      </div>
    </div>
  </div>
  <div class="control-group"><label for="exerciseText" class="control-label optional">Text cvičení</label>
    <div class="controls">
      <quill-editor v-model:content="lesson.exercise.exerciseText"
                    contentType="html"
      ></quill-editor>
      <p class="help-block" style="color: #999;">Text cvičení, například text konverzace se kterou dále student pracuje ve cvičení, apod.</p>
    </div>
  </div>

  <div class="controls">
    <select name="type" id="type" v-model="d.type">
      <option value="fillin"             label="Vyplnění bez možností">Vyplnění bez možností</option>
      <option value="dragdrop" label="Vyplnění pomocí drag &amp; drop">Vyplnění pomocí drag &amp; drop</option>
    </select>
    <p class="help-block" style="color: #999;">Touto volbou můžete ovlivnit, jakým způsobem bude student vyplňovat vybrané mezery v textu.</p>
  </div>

  <button @click.prevent="addItem">Přidat obrázek a text</button><input type="file" name="add-file-ico" class="hidden" v-on:change="handleUploadImage($event)"     ref="pinimagefile" />
  <div id="exerciseImages" class="clearfix">

  <div class="imageWrapper" v-for="t in d.images">
    <div class="btn-group">
      <a title="Vybrat obrázek" class="selectImageAction btn btn-info btn-mini" @click.prevent="selectImage(t)"><i class="icon-picture icon-white"></i></a>
      <a href="#" @click.prevent="removeItem(t)" title="Odebrat obrázek a text ze cvičení" class="deleteImageAction btn btn-danger btn-mini"><i class="icon-remove-sign icon-white"></i></a>
    </div>


    <div class="image">
        <img :src="new_image_src(t)" width="250" height="150" />
    </div>
    <label for="imageText_1">Správné volby:</label>
    <textarea id="imageText_1" name="imageText[1]" v-model="t.text[0]"></textarea>
    <label for="wrongText_1">Špatné volby:</label>
    <textarea id="wrongText_1" name="wrongText[1]" v-model="t.wrong[0]"></textarea>

  </div>


</div>


  <pre>
  {{d}}

    </pre>




</template>

<style scoped>
.imageWrapper {
  padding: 10px;
  margin: 5px 10px 10px 0;
  width: 250px;
  border: 1px solid #cccccc;
  border-radius: 3px;
  background: #fafafa;
  position: relative;
  float: left;
}
.imageWrapper .btn-group {

    position: absolute;
    right: 5px;

}
.imageWrapper .image {

    height: 150px;
    margin: 5px 0 10px 0;
    overflow: hidden;

}
.imageWrapper .imageText, .imageWrapper textarea {

    width: 100%;

}
.btn-mini {

    padding: 2px 6px;
    font-size: 11px;
    line-height: 17px;

}


</style>