<script>

import axios from 'axios';

export default {
  name: "content-attachments",
  props:['lesson'],
  data() {
    return {
      uploadProgress: ''
    };
  },

  methods: {
    uploadFile(event) {
      const files = event.target.files;
      if (files.length > 0) {
        this.uploadFiles(files);
      }
    },
    uploadFiles(files) {
      const formData = new FormData();
      formData.append('content_id', this.lesson.id);
      for (let i = 0; i < files.length; i++) {
        const file = files[i];
        formData.append('file[]', file);
      }
      const xhr = new XMLHttpRequest();
      xhr.open('POST', '/oj/upload-content-attachment', true);
      xhr.upload.onprogress = (event) => {
        if (event.lengthComputable) {
          this.uploadProgress = Math.round((event.loaded / event.total) * 100);
        }
      };
      xhr.onload = () => {
        if (xhr.status === 200) {
          const resp_data = JSON.parse(xhr.responseText);
          for(let i = 0; i < resp_data.content_attachments.length; i++){
            let d = resp_data.content_attachments[i];
            this.lesson.content_attachments.push(d);
          }
        }
      };
      const csfr_token = document.querySelector('meta[name="csrf-token"]').getAttribute('content')
      xhr.setRequestHeader('X-CSRF-Token', csfr_token);
      xhr.send(formData);
    },

    removeAttachment(att){
      axios.delete(`/oj/content-attachment/${att.id}`)
        .then(response => {
          this.lesson.content_attachments = this.lesson.content_attachments.filter(a => a.id !== att.id);

        })
        .catch(error => {
          console.log(error);
        });


    }



  }


}
 // https://studovna.onlinejazyky.cz/media/attachments/stm32f103c8t6_pinout_voltage01.png
/* "content_attachments": [
    {
      "id": 32292,
      "content_id": 205931,
      "name": "rrrr",
      "file": "stm32f103c8t6_pinout_voltage01.png",
      "description": "uuuuuu"
    },
    {
      "id": 32293,
      "content_id": 205931,
      "name": "alfa",
      "file": "IMG_20190728_213843.jpg",
      "description": "betta"
    }
  ]

 */
</script>

<template>

  <table class="table table-striped">
    <thead>
      <tr>
        <th>Name</th>
        <th>File</th>
        <th>Description</th>
        <th>Remove</th>
      </tr>
    </thead>
    <tr v-for="att in lesson.content_attachments">
      <td><input type="text" v-model="att.name" /></td>
      <td>{{att.file}}</td>
      <td><textarea v-model="att.description"></textarea></td>
      <td><a @click="removeAttachment(att)">remove</a></td>
    </tr>
  </table>
  Pridat prilohu:  <input type="file" @change="uploadFile"/>


</template>

<style scoped>

</style>